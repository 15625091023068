import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#ffffff',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 55"
    >
      
      <path
        className={classes.path}
        d="M0 3.27937C0 1.73261 1.19304 0.539581 2.73979 0.539581H13.7889C17.6797 0.539581 20.7253 1.69065 22.7157 3.63309C24.3523 5.31173 25.2756 7.60787 25.2756 10.3057V10.3956C25.2756 15.3476 22.4039 18.3512 18.2493 19.6342L24.1725 27.1041C24.7001 27.7696 25.0538 28.3391 25.0538 29.1785C25.0538 30.6833 23.7708 31.6964 22.4459 31.6964C21.2109 31.6964 20.4135 31.1209 19.796 30.2816L12.2841 20.6893H5.43761V28.9986C5.43761 30.5034 4.24458 31.6964 2.73979 31.6964C1.19304 31.6964 0 30.5034 0 28.9986V3.27937ZM13.3932 15.8692C17.2841 15.8692 19.7601 13.8368 19.7601 10.7014V10.6115C19.7601 7.29613 17.374 5.48559 13.3512 5.48559H5.43761V15.8692H13.3932Z"
      />
      <path
        className={classes.path}
        d="M30.5813 16.091V16.0011C30.5813 7.2961 37.2959 0 46.7982 0C56.3005 0 62.9312 7.20618 62.9312 15.9112V16.0011C62.9312 24.7061 56.2166 31.9962 46.7143 31.9962C37.2119 32.0022 30.5813 24.802 30.5813 16.091ZM57.2298 16.091V16.0011C57.2298 9.98793 52.8533 4.99396 46.7143 4.99396C40.5692 4.99396 36.2827 9.89801 36.2827 15.9112V16.0011C36.2827 22.0142 40.6592 26.9602 46.7982 26.9602C52.9433 26.9662 57.2298 22.1042 57.2298 16.091Z"
      />
      <path
        className={classes.path}
        d="M68.147 16.091V16.0011C68.147 7.20618 74.7297 0 84.1001 0C88.8722 0 92.0137 1.41485 94.7055 3.48918C95.2811 3.93282 95.7667 4.68222 95.7667 5.65343C95.7667 7.15822 94.5736 8.30329 93.0689 8.30329C92.3195 8.30329 91.7859 8.0395 91.4322 7.77572C89.3099 6.04911 87.0137 4.99396 84.0521 4.99396C78.1769 4.99396 73.8424 9.85604 73.8424 15.9112V16.0011C73.8424 22.0562 78.1289 26.9602 84.0521 26.9602C87.3255 26.9602 89.5317 25.8991 91.7859 23.9986C92.1816 23.6449 92.7571 23.3811 93.4226 23.3811C94.7895 23.3811 95.9825 24.5322 95.9825 25.8991C95.9825 26.7384 95.5868 27.4039 95.1012 27.8415C92.1816 30.4075 88.8722 31.9962 83.8783 31.9962C74.8616 32.0022 68.147 24.9758 68.147 16.091Z"
      />
      <path
        className={classes.path}
        d="M102.134 3.00956C102.134 1.50477 103.327 0.311737 104.873 0.311737C106.378 0.311737 107.571 1.50477 107.571 3.00956V16.091L122.241 1.33091C122.859 0.66545 123.524 0.311737 124.495 0.311737C126 0.311737 127.013 1.50477 127.013 2.8297C127.013 3.66903 126.66 4.28654 126.042 4.86207L116.408 14.1426L126.84 27.0921C127.283 27.6677 127.589 28.1952 127.589 29.0346C127.589 30.5333 126.396 31.6844 124.849 31.6844C123.788 31.6844 123.123 31.1568 122.553 30.4015L112.607 17.7157L107.571 22.5778V28.9866C107.571 30.4914 106.378 31.6844 104.873 31.6844C103.327 31.6844 102.134 30.4914 102.134 28.9866V3.00956Z"
      />
      <path
        className={classes.path}
        d="M170.856 5.57551H163.344C161.929 5.57551 160.826 4.42444 160.826 3.05755C160.826 1.68466 161.929 0.539581 163.344 0.539581H183.896C185.268 0.539581 186.372 1.69065 186.372 3.05755C186.372 4.43044 185.268 5.57551 183.896 5.57551H176.336V28.9986C176.336 30.5034 175.101 31.6964 173.596 31.6964C172.091 31.6964 170.856 30.5034 170.856 28.9986V5.57551Z"
      />
      <path
        className={classes.path}
        d="M270.424 5.57551H262.912C261.497 5.57551 260.394 4.42444 260.394 3.05755C260.394 1.68466 261.497 0.539581 262.912 0.539581H283.463C284.836 0.539581 285.939 1.69065 285.939 3.05755C285.939 4.43044 284.836 5.57551 283.463 5.57551H275.909V28.9986C275.909 30.5034 274.674 31.6964 273.169 31.6964C271.665 31.6964 270.43 30.5034 270.43 28.9986V5.57551H270.424Z"
      />
      <path
        className={classes.path}
        d="M152.379 18.3032C153.704 18.3032 154.807 17.2421 154.807 15.9172C154.807 14.5443 153.704 13.4412 152.379 13.4412H143.506V18.3032H152.379Z"
      />
      <path
        className={classes.path}
        d="M154.591 26.6125H139.082V5.40166H154.375C155.7 5.40166 156.803 4.34052 156.803 2.96762C156.803 1.64269 155.7 0.539581 154.375 0.539581H136.39C134.843 0.539581 133.65 1.73261 133.65 3.27937V28.7348C133.65 30.2816 134.843 31.4746 136.39 31.4746H154.597C155.922 31.4746 157.025 30.4135 157.025 29.0466C157.019 27.7157 155.916 26.6125 154.591 26.6125Z"
      />
      <path
        className={classes.path}
        d="M296.892 3.00956C296.892 1.50477 295.699 0.311737 294.195 0.311737C292.648 0.311737 291.455 1.50477 291.455 3.00956V28.9926C291.455 30.4974 292.648 31.6904 294.195 31.6904C295.699 31.6904 296.892 30.4974 296.892 28.9926V3.00956Z"
      />
      <path
        className={classes.path}
        d="M314.35 0.317749C312.845 0.317749 311.652 1.51079 311.652 3.01557V13.3992H302.066V18.4351H311.652V28.9986C311.652 30.5034 312.845 31.6964 314.35 31.6964C315.897 31.6964 317.09 30.5034 317.09 28.9986V3.01557C317.09 1.51079 315.897 0.317749 314.35 0.317749Z"
      />
      <path
        className={classes.path}
        d="M239.795 19.1845L240.34 17.9375L239.795 19.1845Z"
      />
      <path
        className={classes.path}
        d="M260.166 27.9375L248.679 2.30215C248.062 0.929259 247.001 0.0899353 245.454 0.0899353H245.19C243.643 0.0899353 242.54 0.929259 241.923 2.30215L230.43 27.9375C230.208 28.3811 230.076 28.7768 230.076 29.1725C230.076 30.5873 231.18 31.6904 232.594 31.6904C233.745 31.6904 234.669 31.0729 235.112 29.9698L236.437 26.9423L239.801 19.1845L240.346 17.9375L240.364 17.8896L245.232 6.68461L250.67 19.1905H244.501L242.642 24.0106H252.744L255.262 29.802C255.748 30.953 256.635 31.7024 257.912 31.7024C259.369 31.7024 260.472 30.5514 260.472 29.0945C260.478 28.7348 260.394 28.3331 260.166 27.9375Z"
      />
      <path
        className={classes.path}
        d="M240.358 17.8896L240.34 17.9386L240.358 17.8896Z"
      />
      <path
        className={classes.path}
        d="M217.342 0.539581H207.882C206.335 0.539581 205.142 1.73261 205.142 3.27937V28.9986C205.142 30.5034 206.335 31.6964 207.882 31.6964C209.387 31.6964 210.58 30.5034 210.58 28.9986V5.48559H216.899C220.964 5.48559 223.571 7.38606 223.571 11.0071V11.0971C223.571 14.3225 221.006 16.6246 216.899 16.6246H210.58V21.5286H216.725C223.529 21.5286 229.099 17.9016 229.099 10.9652V10.8752C229.099 4.64627 224.591 0.539581 217.342 0.539581Z"
      />

    </svg>
  );
};

export default LogoFull;
