import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
      <svg
        className={classes.svg}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
      >
        <path
          d="M28.0154 42.6903C29.1496 42.6903 30.2839 42.2552 31.1462 41.3929L64.9706 7.56854C66.7029 5.83615 66.7029 3.03169 64.9706 1.29929C63.2382 -0.433098 60.4337 -0.433098 58.7013 1.29929L24.8769 35.1237C23.1445 36.8561 23.1445 39.6605 24.8769 41.3929C25.747 42.2552 26.8812 42.6903 28.0154 42.6903Z"
          fill="#DA0000"
        />
        <path
          d="M81.1914 17.5123C79.459 15.7799 76.6545 15.7799 74.9221 17.5123L1.29929 91.1351C-0.433098 92.8675 -0.433098 95.672 1.29929 97.4044C2.16161 98.2667 3.29582 98.7017 4.43003 98.7017C5.56424 98.7017 6.69845 98.2667 7.56077 97.4044L81.1836 23.7815C82.916 22.0569 82.916 19.2447 81.1914 17.5123Z"
          fill="#8FFFB4"
        />
        <path
          d="M97.4043 33.7331C95.6719 32.0007 92.8675 32.0007 91.1351 33.7331L44.8965 79.9717C43.1641 81.7041 43.1641 84.5085 44.8965 86.2409C45.7665 87.1032 46.9008 87.5383 48.0272 87.5383C49.1614 87.5383 50.2956 87.1032 51.1579 86.2409L97.3965 40.0023C99.1367 38.27 99.1367 35.4655 97.4043 33.7331Z"
          fill="#F9EB37"
        />
      </svg>
  );
};

export default LogoIcon;
